import { Search, Icon, Swipe, SwipeItem } from 'vant'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import Clue from '../../common/Clue.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        Clue: Clue,
        [Search.name]: Search,
        [Icon.name]: Icon,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem
    },
    data () {
        return {
            loadingShow: false,
            finished: false,
            keyword: '',
            page: { current: 0, size: 20, last: 0 },
            informList: []
        }
    },
    mounted () {
        this.retrieveInformList()
        this.createVistClue()
    },
    methods: {
        search () {
            this.page.current = 0
            this.finished = false
            this.informList = []
            this.retrieveInformList()
            this.createSearchClue()
        },
        async retrieveInformList () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var pd = { name: this.keyword, current: this.page.current, size: this.page.size }
                var { data: res } = await this.$http.post(this.BMS_URL + '/inform/taskAPC/retrieveTaskList', this.$qs.stringify(pd))
                if (res.status === '200') {
                    console.log(res.data)
                    this.informList = this.informList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        detail (code) {
            var key = window.const.global.INFORM_DETAIL_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/inform/detail', query: { code: code } })
        },
        createVistClue () {
            var param = { url: window.location.href }
            var content = '公招公告首页访问'
            this.$refs.clue.createClue('INFORM_VIST_HOME', '0', JSON.stringify(param), content)
        },
        createSearchClue () {
            var date = new Date()
            var dt = date.getTime()
            var param = { keyword: this.keyword, url: window.location.href }
            var content = '公招公示搜索'
            this.$refs.clue.createClue('INFORM_SEARCH_INFORM', dt, JSON.stringify(param), content)
        },
        back () {
            var back = window.sessionStorage.getItem(window.const.global.INFORM_HOME_INDEX_BACK)
            if (back !== undefined && back !== null && back !== '') {
                window.location.href = back
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '已返回到最上层'
                })
            }
        }
    }
}
